<template>
  <f-page :title="$t('labels.fileSearch')">
    <f-form @submit="refreshFileDatatable">
      <f-card>
        <f-row>
          <f-col md="12">
            <f-input
              v-model="search.no"
              :label="$t('labels.fileNo')"
            />
          </f-col>
          <f-col md="6">
            <f-select
              v-model="search.contractId"
              :data-url="`/companyApp/select/contracts/${user.companyId}`"
              :label="$t('labels.contract')"
            />
          </f-col>
          <f-col md="6">
            <f-input
              v-model="search.policyNo"
              :label="$t('labels.policyNumber')"
            />
          </f-col>
          <f-col md="6">
            <f-input
              v-model="search.plateNo"
              :label="$t('labels.vehiclePlate')"
            />
          </f-col>
          <f-col md="6">
            <f-select
              v-model="search.serviceTypeId"
              data-url="/companyApp/select/serviceTypes"
              :label="$t('labels.serviceProvided')"
            />
          </f-col>
          <f-col md="6">
            <f-input
              v-model="search.startDate"
              :label="$t('labels.serviceCreatedDateRange')"
              type="date"
            />
          </f-col>
          <f-col md="6">
            <f-input
              v-model="search.endDate"
              inline
              type="date"
            />
          </f-col>
          <f-col md="6">
            <f-input
              v-model="search.networkAssignedStartDate"
              :label="$t('labels.serviceAssignedDateTimeInterval')"
              type="date"
            />
          </f-col>
          <f-col md="6">
            <f-input
              v-model="search.networkAssignedEndDate"
              inline
              type="date"
            />
          </f-col>
        </f-row>
        <template #footer>
          <f-button
            class="float-right"
            icon="search"
            :label="$t('labels.search')"
            type="submit"
            variant="primary"
          />
          <f-button
            class="float-right mr-2"
            icon="eraser"
            :label="$t('labels.reset')"
            variant="light"
            @click="clearSearch"
          />
        </template>
      </f-card>
    </f-form>
    <f-card>
      <f-datatable
        ref="fileDatatable"
        :actions="[
          {
            name: 'fileDetail',
            icon: 'info-circle',
            title: $t('labels.show')
          }
        ]"
        :columns="companySearchColumns"
        data-url="/companyApp/files"
        :features="{
          serverSidePagination: true
        }"
        :options="{ rowStyle: fileDatatableRowStyle }"
        :query-params="search"
        @action="onClickAction"
      />
    </f-card>
    <f-modal
      ref="modalFileDetail"
      cancel-disabled
      hide-footer
      lazy
      submit-disabled
      :title="$t('labels.fileDetails')"
    >
      <template>
        <f-tabs pills>
          <f-tab
            class="py-3"
            :title="$t('labels.generalInformation')"
          >
            <TabFileDetail :file-query-data="fileQueryData" />
          </f-tab>
          <f-tab
            class="py-3"
            :title="$t('labels.additionalFilesAndImages')"
          >
            <TabAttachments :file-query-data="fileQueryData" />
          </f-tab>
        </f-tabs>
      </template>
    </f-modal>
  </f-page>
</template>

<script>
import { mapGetters } from 'vuex'
import { companySearchColumns } from '@/data/columns'
import { fileServiceStatus } from '@/data/enums'
import TabFileDetail from './TabFileDetail'
import TabAttachments from './TabAttachments'

export default {
  components: {
    TabFileDetail,
    TabAttachments
  },
  data () {
    return {
      search: {},
      companySearchColumns,
      fileQueryData: {},
    }
  },
  computed: { ...mapGetters(['user', 'isAuthenticated']) },
  async mounted () {
    if (!this.isAuthenticated) {
      this.$router.push({ name: 'login' })
    }

    const { id } = this.$route.params

    if (id) {
      this.fileQueryData = await this.$http.get(`/companyApp/files/${id}`)
      this.$refs.modalFileDetail.show()
    }
  },
  methods: {
    refreshFileDatatable () {
      this.$refs.fileDatatable.refresh()
    },
    onClickAction (row) {
      this.fileQueryData = row
      this.$refs.modalFileDetail.show()
    },
    clearSearch () {
      this.search = {}
    },
    fileDatatableRowStyle (row) {
      if (row.fileService?.status === fileServiceStatus.CANCELED) {
        return { css: { 'background-color': 'pink' } };
      }

      return {};
    }

  }
}
</script>
