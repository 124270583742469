/* eslint-disable spellcheck/spell-checker */
import Vue from '../main'
import { insuranceCategories } from '@/data/enums'

const $t = str => Vue.$i18n.t(str)
const dateService = Vue.$flex.dateService

export const isInfoInsuranceCategory = insuranceCategoryId => {
  const infoInsuranceCategories = [
    insuranceCategories.SIGORTA_BILGI,
    insuranceCategories.SIGORTA_TALEP_SIKAYET,
    insuranceCategories.OTOMOTIV_BILGI,
    insuranceCategories.OTOMOTIV_TALEP_SIKAYET,
    insuranceCategories.DIGER_BILGI,
    insuranceCategories.DIGER_TALEP_SIKAYET
  ]

  const isInfoInsuranceCategory = infoInsuranceCategories.includes(
    insuranceCategoryId
  )

  return isInfoInsuranceCategory
}

export const companySearchColumns = [
  { field: 'no', title: $t('labels.fileNo') },
  {
    field: 'fileInsurancePolicy.contract.contractType.name',
    title: $t('labels.contract')
  },
  {
    field: 'createdAt',
    title: $t('labels.dateTime'),
    formatter: value => dateService.getDateTime(value)
  },
  {
    field: 'fileCustomer.fullname',
    title: $t('labels.whoTakeService'),
    formatter: (value, row) =>
      isInfoInsuranceCategory(
        row.fileInsurancePolicy.contract.contractType.insuranceCategoryId
      )
        ? `${row.fileCaller.fullname}`
        : value
  },
  {
    field: 'fileInsurancePolicy.policyNo',
    title: $t('labels.policyNumber')
  },
  { field: 'fileVehicle.plateNo', title: $t('labels.plate') },
  {
    field: 'fileVehicle.vehicleModel.vehicleBrand.name',
    title: $t('labels.brand')
  },
  { field: 'fileVehicle.vehicleModel.name', title: $t('labels.model') },
  {
    field: 'fileService.serviceType.name',
    title: $t('labels.serviceProvided'),
    formatter: (value, row) =>
      row.fileService && row.fileService.status === 'canceled'
        ? `İPTAL-${value}`
        : value
  },
  {
    field: 'fileService.networkAssignedDate',
    title: $t('labels.serviceAssignedDateTimeInterval'),
    formatter: value => dateService.getDateTime(value)
  }
]

export const fileRequestColumns = [
  {
    field: 'status',
    title: $t('labels.status'),
    formatter: value => ({
      'waiting': $t('labels.waiting'),
      'assumed': $t('labels.assumed'),
      'deleted': $t('labels.deleted')
    }[value])
  },
  {
    field: 'id',
    title: $t('labels.requestNumber')
  },
  {
    field: 'file.no',
    title: $t('labels.fileNo')
  },
  {
    field: 'createdAt',
    title: $t('labels.registrationDate'),
    formatter: value => dateService.getDateTime(value)
  },
  {
    field: 'customerFullname',
    title: $t('labels.customerFullname')
  },
  {
    field: 'claimCounty.city.name',
    title: $t('labels.cityCounty'),
    formatter: (value, row) => value && `${value} / ${row.claimCounty.name}`
  },
  {
    field: 'assumedBy.fullname',
    title: $t('labels.relatedPerson'),
  },
  {
    field: 'createdBy',
    title: $t('labels.requestCreatedBy'),
    formatter: (value, row) => value ? `${value.firstname} ${value.lastname}` : (row.createdByCompanyUser.username || $t(`labels.${row.createdByWho}`))
  },
]

export const insurancePolicyColumns = [
  {
    field: 'firstname',
    title: $t('labels.insuredName')
  },
  {
    field: 'lastname',
    title: $t('labels.insuredLastName')
  },
  { field: 'contract.contractType.name', title: $t('labels.contract'), },
  { field: 'no', title: $t('labels.policyNumber') },
  {
    field: 'startDate',
    title: $t('labels.startDate'),
    formatter: value => (value ? dateService.getDate(value) : null)
  },
  {
    field: 'endDate',
    title: $t('labels.endDate'),
    formatter: value => (value ? dateService.getDate(value) : null)
  },
  { field: 'identificationNo', visible: false },
  { field: 'cityName', title: $t('labels.city'), visible: false },
  { field: 'countyName', title: $t('labels.county'), visible: false },
  { field: 'address', visible: false },
  { field: 'vehiclePlateNo', title: $t('labels.plate') },
  { field: 'vehicleBrandName', title: $t('labels.vehicleBrand') },
  { field: 'vehicleModelName', title: $t('labels.vehicleModel') },
  {
    field: 'vehicleYear',
    title: $t('labels.vehicleModelYear'),
    visible: false
  },
  {
    field: 'vehicleRegistrationType',
    title: $t('labels.licenseType'),
    visible: false
  },
  {
    field: 'vehicleGearboxType',
    title: $t('labels.gearboxType'),
    visible: false
  },
  { field: 'vehicleDrivingType', visible: false },
  {
    field: 'registrationDate',
    title: $t('labels.vehicleRegistrationDate'),
    visible: false
  },
  {
    field: 'hasVehicleAbroadSupply',
    title: $t('labels.abroadSupply'),
    visible: false
  },
  {
    field: 'hasAssistantSupply',
    title: $t('labels.assistantSupply'),
    visible: false
  },
  {
    field: 'hasRentalCarSupply',
    title: $t('labels.rentalCarSupply'),
    visible: false
  },
  {
    field: 'insuredCityName',
    title: $t('labels.insuredCity'),
    visible: false
  },
  {
    field: 'insuredCountyName',
    title: $t('labels.insuredCounty'),
    visible: false
  },
  { field: 'insuredAddress', visible: false },
  { field: 'agentNo', visible: false },
  { field: 'tecditNo', visible: false },
  { field: 'zeyilNo', visible: false },
  { field: 'zeyilCode', visible: false },
  { field: 'zeyilName', visible: false },
  { field: 'taxNumber', visible: false },
  {
    field: 'arrangementDate',
    visible: false,
    formatter: value => value && dateService.getDate(value)
  },
  { field: 'productCode', visible: false },
  { field: 'isCancelled', tTitle: 'cancelStatus', visible: false }
]

export const fileAttachmentColumns = [
  {
    field: 'name',
    title: $t('labels.attachmentTitle'),
    formatter: (value, row) => `<a href="${row.url}" target="_blank">${value}</a>`
  },
  {
    field: 'createdBy',
    title: $t('labels.uploadedBy'),
    formatter: (value, row) => {
      if (value) {
        return value.fullname
      } else {
        return row.fromNetwork ? i18n.t('labels.network') : null
      }
    }
  },
  {
    field: 'createdAt',
    title: $t('labels.uploadedDate'),
    formatter: value => dateService.getDateTime(value)
  }
]
