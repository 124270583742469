<template>
  <f-card>
    <f-datatable
      ref="attachmentDatatable"
      :columns="fileAttachmentColumns"
      :data-url="`/companyApp/files/${fileQueryData.fileGroupId}/attachments`"
      :features="{
        delete: false,
        pagination: false,
        fullscreen: false,
        showColumns: false
      }"
    />
  </f-card>
</template>

<script>
import { fileAttachmentColumns } from '@/data/columns'

export default {
  props: {
    fileQueryData: {
      type: Object
    }
  },
  data () {
    return {
      fileAttachmentColumns
    }
  },

  methods: {
    async loadAttachments () {
      this.$refs.attachmentDatatable.refresh()
    },
  }
}
</script>
