export const serviceTypeRentalCar = {
  KIRALIK_ARAC: 24,
  YOLA_DEVAM_ARACI: 35
}

export const insuranceCategories = {
  SIGORTA_KASKO_YOL_YARDIM: 1,
  SIGORTA_KASKO_IKAME: 2,
  SIGORTA_TRAFIK: 3,
  SIGORTA_KONUT: 4,
  SIGORTA_IS_YERI: 5,
  SIGORTA_FERDI_KAZA_YOL_YARDIM: 6,
  SIGORTA_IMM: 7,
  SIGORTA_BILGI: 8,
  SIGORTA_TALEP_SIKAYET: 9,
  SIGORTA_FERDI_KAZA_KONUT_YARDIM: 10,
  OTOMOTIV_YOL_YARDIM: 11,
  OTOMOTIV_BILGI: 12,
  OTOMOTIV_TALEP_SIKAYET: 13,
  DIGER_YOL_YARDIM: 14,
  DIGER_IKAME: 15,
  DIGER_KONUT: 16,
  DIGER_BILGI: 17,
  DIGER_TALEP_SIKAYET: 18
}

export const fileRequestStatuses = {
  WAITING: 'waiting',
  ASSUMED: 'assumed',
  DELETED: 'deleted'
}

export const companyCategories = {
  SIGORTA: 1,
  OTOMOTIV: 2,
  DIGER: 3
}

/* eslint-disable spellcheck/spell-checker */
export const fileServiceStatus = {
  CANCELED: 'canceled', // 'Hizmet İptal Edildi',
  CLOSED: 'closed', // Hizmet Kapalı
  OPENED: 'opened', // Açıldı
  WAITING: 'waiting', // 'Hizmet Bekliyor',
  DIRECTED: 'directed', // 'Hizmet Yönlendirildi',
  PROVIDED: 'provided', // 'Hizmet Sağlandı',
}
