<template>
  <f-card>
    <f-row>
      <f-col style="margin-left:10%">
        <table class="table row table-borderless w-100 m-0">
          <tbody class="col-lg-6 col-md-6  col-xl-6 p-0">
            <tr>
              <td>
                <strong>{{ $t('labels.serviceCreatedDateAndTime') }}:</strong>
                {{
                  fileQueryData.createdAt
                    ? getDateTime(fileQueryData.createdAt, 'dd/MM/yyyy HH:mm')
                    : ' -'
                }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{{ $t('labels.fileNo') }}:</strong>
                {{ fileQueryData.no ? fileQueryData.no : ' -' }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{{ $t('labels.contract') }}:</strong>
                {{
                  fileQueryData.fileInsurancePolicy.contract
                    ? fileQueryData.fileInsurancePolicy.contract.contractType.name
                    : ' -'
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table row table-borderless w-100 m-0">
          <tbody class="col-lg-6 col-md-6  col-xl-6 p-0">
            <tr>
              <td>
                <strong>{{ $t('labels.policyNumber') }}:</strong>
                {{
                  fileQueryData.fileInsurancePolicy.policyNo
                    ? fileQueryData.fileInsurancePolicy.policyNo
                    : ' -'
                }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{{ $t('labels.policyStartAndEndDate') }}:</strong>
                {{
                  fileQueryData.fileInsurancePolicy.startDate
                    ? getDateTime(
                      fileQueryData.fileInsurancePolicy.startDate,
                      'dd/MM/yyyy'
                    )
                    : ' -'
                }}
                -
                {{
                  fileQueryData.fileInsurancePolicy.endDate
                    ? getDateTime(
                      fileQueryData.fileInsurancePolicy.endDate,
                      'dd/MM/yyyy'
                    )
                    : ' -'
                }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{{ $t('labels.city') }}:</strong>
                {{
                  fileQueryData.fileClaim.county
                    ? fileQueryData.fileClaim.county.city.name
                    : ' -'
                }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{{ $t('labels.county') }}:</strong>
                {{
                  fileQueryData.fileClaim.county
                    ? fileQueryData.fileClaim.county.name
                    : ' -'
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </f-col>
      <f-col>
        <table class="table row table-borderless w-100 m-0">
          <tbody class="col-lg-6 col-md-6  col-xl-6 p-0">
            <tr>
              <td>
                <strong>{{ $t('labels.plateNo') }}:</strong>
                {{
                  fileQueryData.fileVehicle && fileQueryData.fileVehicle.plateNo
                    ? fileQueryData.fileVehicle.plateNo
                    : ' -'
                }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{{ $t('labels.vehicleType') }}:</strong>
                {{
                  fileQueryData.fileVehicle &&
                    fileQueryData.fileVehicle.vehicleModel &&
                    fileQueryData.fileVehicle.vehicleModel.vehicleBrand.name
                    ? fileQueryData.fileVehicle.vehicleModel.vehicleBrand.name
                    : ' -'
                }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{{ $t('labels.vehicleModel') }}:</strong>
                {{
                  fileQueryData.fileVehicle &&
                    fileQueryData.fileVehicle.vehicleModel &&
                    fileQueryData.fileVehicle.vehicleModel.name
                    ? fileQueryData.fileVehicle.vehicleModel.name
                    : ' -'
                }}
              </td>
            </tr>
            <tr v-if="serviceTypeRentalCar">
              <td>
                <strong>{{ $t('labels.howManyDaysInExper') }}:</strong>
                {{
                  fileQueryData.fileService && fileQueryData.fileService.repairDay
                    ? fileQueryData.fileService.repairDay
                    : ' -'
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table row table-borderless w-100 m-0">
          <tbody class="col-lg-6 col-md-6  col-xl-6 p-0">
            <tr>
              <td>
                <strong>{{ $t('labels.serviceProvided') }}:</strong>
                {{
                  fileQueryData.fileService &&
                    fileQueryData.fileService.serviceType
                    ? fileQueryData.fileService.serviceType.name
                    : ' -'
                }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{{ $t('labels.serviceDirectedDateTime') }}:</strong>
                {{
                  fileQueryData.fileService &&
                    fileQueryData.fileService.directedAt
                    ? getDateTime(
                      fileQueryData.fileService.directedAt,
                      'dd/MM/yyyy HH:mm'
                    )
                    : ' -'
                }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{{ $t('labels.serviceStatus') }}:</strong>
                <br>
                {{
                  serviceStatus
                }}
              </td>
            </tr>
            <tr v-if="fileQueryData.fileService && fileQueryData.fileService.status === fileServiceStatus.CANCELED">
              <td>
                <strong>{{ $t('labels.cancellationReason') }}:</strong>
                <br>
                {{
                  fileQueryData.fileService.cancellationReason
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </f-col>
    </f-row>
  </f-card>
</template>

<script>
import { getDateTime } from '@/utils'
import { serviceTypeRentalCar, fileServiceStatus } from '@/data/enums'

export default {
  props: {
    fileQueryData: {
      type: Object
    }
  },
  data () {
    return {
      fileServiceStatus,
      getDateTime,
    }
  },
  computed: {
    serviceTypeRentalCar () {
      let isRentalCar = false

      if (this.fileQueryData.fileService) {
        isRentalCar = Object.values(serviceTypeRentalCar).includes(
          this.fileQueryData.fileService.serviceTypeId
        )
      }

      return isRentalCar
    },
    serviceStatus () {
      const { status, isInterrupted, serviceType } = this.fileQueryData.fileService || {};

      if (status) {
        if (isInterrupted) {
          return this.$t('labels.interrupted');
        } else if (status === fileServiceStatus.CANCELED) {
          return `İPTAL - ${serviceType.name}`;
        } else if (status === fileServiceStatus.CLOSED) {
          return this.$t('labels.fileServiceStatus.provided');
        } else {
          return this.$t(`labels.fileServiceStatus.${status}`);
        }
      }

      return '-';
    }


  }
}
</script>
